import types from '../types'
import {initialState} from './initialState'

export default function(state = JSON.parse(JSON.stringify(initialState)), action) {
  switch (action.type) {
    case types.STORE_PAYLOAD:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
