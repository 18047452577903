import React, {useState, useEffect} from 'react'
import {Table, TableEntry} from '@lazarusai/forms-ui-components'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload.js'
import {getLogData} from '../actions/getLogData.js'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent'
import '../styles/Logs.css'
import '../styles/Table.css'
import Helpers from '../Helpers.js'

function ModelUsage(props) {
  const [entries, setEntries] = useState([])

  useEffect(() => {
    if (props.orgModelMetrics?.[props.orgId] && props.orgModelMetrics?.[props.orgId]?.length) {
      const newEntries = props.orgModelMetrics?.[props.orgId].map((model, i) => {
        return (
          <TableEntry
            key={`${model.model_id}-${i}-${model?.timestamp || Date.now()}`}
            content={[
              model.model_id,
              model?.model_name?.length ? model?.model_name : props.modelInformation?.[model.model_id]?.public?.metadata?.name || 'N/A',
              Helpers.timeStampToDate(model.timestamp * 1000),
              model.successes,
              model.failures,
              model.pages,
              model.questions,
            ]}
          />
        )
      })
      setEntries(newEntries)
    }
  }, [props.orgModelMetrics, props.orgModelMetricsTime])

  return (
    <>
      {(props.orgModelMetrics?.[props.orgId] && Object.keys(props.orgModelMetrics?.[props.orgId]).length > 0) ?
        <Table
          title='Models'
          columnTitles={['ID', 'Name', 'Last Use', 'Successes', 'Failures', 'Total Pages', 'Total Questions']}
          entries={entries}
          theme={props.theme}
          showTotal={true}
          showCount={true}
          className='table-container'
        /> :
        <></>
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  orgId: state.userReducer.orgId,
  theme: state.userReducer.theme,
  modelInformation: state.userReducer.modelInformation,
  orgModelMetrics: state.userReducer.orgModelMetrics,
  orgModelMetricsTime: state.userReducer.orgModelMetricsTime,
})

export default connect(mapStateToProps, {
  getLogData,
  storePayload,
  logAnalyticsEvent,
})(ModelUsage)
