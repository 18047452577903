import types from '../types'

export const setOrganizationRequireMFA = (database, orgId, requireMFA) => (dispatch) => {
  const dbRef = database.ref(`organizations/${orgId}/orgDetails/requireMFA`)
  dbRef
      .set(requireMFA)
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: `Organization MFA Enforcement successfully set to ${requireMFA}`,
            notificationIcon: 'check',
            notificationType: 2,
          },
        })
      })
      .catch((err) => {
        console.log('error', err)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Organization MFA Enforcement update failed',
            notificationIcon: 'warning',
            notificationType: 3,
          },
        })
      })
}
