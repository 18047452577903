export const initialState = {
  /* Default values here */
  isLoading: true,
  hasAttemptedSignIn: false,
  isNew: true,
  logData: {},
  isNotificationVisible: true,
  isFirstTimeUser: false,
  theme: 'dark',
  isSaveCred: true,
  isModelMenuShowing: false,
  timeOfLastAction: Date.now(),
  mfaModalPage: 1,
  subOrganizationMetrics: null,
  selectedModel: null,
  isVerboseMode: true,
  lastRequest: null,
  canceledRequests: [],
  fillablePDFCheckCounter: 0,
  orgModularMetricsFeed: {},
  vkgDomain: false,
  canceledRequests: [],
}
