import Helpers from '../Helpers'
import types from '../types'

export const getOrgModelMetrics = (orgId) => (dispatch, getState) => {
  const authKey = getState().userReducer.authKey
  const url = process.env.REACT_APP_METRICS_URL + 'api/query/models?filter_demo_init=true&filter_indirect_calls=true'
  return Helpers.fetchGet(url, {'orgId': orgId, 'authKey': authKey})
      .then((resp) => {
        if (resp?.status === 'SUCCESS') {
          const orgModelMetrics = getState().userReducer.orgModelMetrics || {}
          let invoicesStats = {
            'model_id': '',
            'model_name': '',
            'timestamp': '',
            'requests': 0,
            'successes': 0,
            'failures': 0,
            'pages': 0,
            'questions': 0,
          }
          let invoicesWithKVPStats = {
            'model_id': '',
            'model_name': '',
            'timestamp': '',
            'requests': 0,
            'successes': 0,
            'failures': 0,
            'pages': 0,
            'questions': 0,
          }
          let hasInvoiceData = false
          const models = resp.models.filter((model) => {
            if (model?.model_id === 'invoices') {
              invoicesStats = structuredClone(model)
              hasInvoiceData = true
              return false
            } else if (model?.model_id === 'invoices-with-kvp') {
              invoicesWithKVPStats = structuredClone(model)
              hasInvoiceData = true
              return false
            }
            return model?.model_id?.length
          })
          // Combine Invoices models - parseInt is to avoid undefined values for one model or the other
          if (hasInvoiceData) {
            models.push({
              'model_id': 'invoices',
              'model_name': 'invoices',
              'timestamp': invoicesStats?.['timestamp'] > invoicesWithKVPStats?.['timestamp'] ? invoicesStats?.['timestamp']: invoicesWithKVPStats?.['timestamp'],
              'requests': parseInt(invoicesStats?.['requests'] || 0) + parseInt(invoicesWithKVPStats?.['requests'] || 0),
              'successes': parseInt(invoicesStats?.['successes'] || 0) + parseInt(invoicesWithKVPStats?.['successes'] || 0),
              'failures': parseInt(invoicesStats?.['failures'] || 0) + parseInt(invoicesWithKVPStats?.['failures'] || 0),
              'pages': parseInt(invoicesStats?.['pages'] || 0) + parseInt(invoicesWithKVPStats?.['pages'] || 0),
              'questions': parseInt(invoicesStats?.['questions'] || 0) + parseInt(invoicesWithKVPStats?.['questions'] || 0),
            })
          }
          orgModelMetrics[orgId] = models
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              orgModelMetrics,
              orgModelMetricsTime: Date.now(),
            },
          })
          return true
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: 'Error pulling organization model metrics.',
              notificationIcon: 'warning',
              notificationType: 2,
            },
          })
          return false
        }
      }).catch((error) => {
        console.log(error)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error pulling organization model metrics.',
            notificationIcon: 'warning',
            notificationType: 2,
          },
        })
        return false
      })
}
